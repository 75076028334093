import { useEffect, useRef } from "react";
import menuLinks from "./menu-links.json";
import { Triangle } from "../../icons/triangle";
import styles from './styles.module.scss';
import { DoubleArrowSvg } from "../../icons/doubleArrow";

interface Links {
  title: string;
  url: string;
  description: string;
  underline: boolean;
  cta: boolean;
  separateCTA?: boolean;
  ctaTitle?: string;
  eyebrow?: string;
  disabled?: boolean;
  picture?: string;
  doubleArrow?: boolean;
  secondaryLinks?: any;
  fontWeight?: string;
  noLink?: boolean;
  noHover?: boolean;
}

const HeaderDropdowns = () => {
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [])

  const subMenuRef = useRef<(HTMLDivElement | any)[]>([])
  const topMenuArrowRef = useRef<(HTMLSpanElement | any)[]>([])
  const topMenuLinkRef = useRef<(HTMLAnchorElement | any)[]>([])

  const topMenuClick = (e:  React.MouseEvent<HTMLAnchorElement, MouseEvent>, position: number) => {
    e.preventDefault();
    const pageWrapper = document.getElementById("page-wrapper");
    let pageWrapperClasses: string | string[] = [];
  
    if (pageWrapper !== null) {
      pageWrapperClasses = pageWrapper.className.split(' ');
    }

    // First close any that are already opened.
    // then "unclick" the top menu link
    subMenuRef.current.map((_, idx) => {
      if (idx !== position) {
        subMenuRef.current[idx].className = `hidden ${styles.subMegaMenu}`;
        topMenuArrowRef.current[idx].className = `${styles.topMenuArrow} hidden`;
        topMenuLinkRef.current[idx].setAttribute("clicked", "false");
      }
    })

    const classNameArray = subMenuRef.current[position]?.className.split(" ");
    const originalClassName = subMenuRef.current[position].className;
    const expand = originalClassName.includes("expand") ? " expand" : "";

    if (classNameArray?.includes("hidden")) {
      if (typeof subMenuRef.current[position] !== "undefined" && typeof subMenuRef.current[position] === "object") {
        topMenuLinkRef.current[position].setAttribute("clicked", "true");
        subMenuRef.current[position].className = `visible ${styles.subMegaMenu} opened${expand}`;
        topMenuArrowRef.current[position].className = `${styles.topMenuArrow} opened`;
        if (!pageWrapperClasses.includes("blurred") && pageWrapper !== null) {
          pageWrapper.classList.add("blurred");
        } 
      }
    }
    else {
      if (typeof subMenuRef.current[position] !== "undefined" && typeof subMenuRef.current[position] === "object") {
        subMenuRef.current[position].className = `hidden ${styles.subMegaMenu}${expand}`;
        topMenuArrowRef.current[position].className = `${styles.topMenuArrow} hidden`;
        if (pageWrapperClasses.includes("blurred")  && pageWrapper !== null) {
          pageWrapper.classList.remove("blurred");
        } 
      }
    }
  }

  const handleClickOutside = (e: any) => {
    const pageWrapper = document.getElementById("page-wrapper");
    let pageWrapperClasses: string | string[] = [];

    if (pageWrapper !== null) {
      pageWrapperClasses = pageWrapper.className.split(' ');
    }

    if (e.target.className !== `${styles.topMenuLink}`) {
      subMenuRef.current.map((_, idx) => {
        topMenuLinkRef.current[idx].setAttribute("clicked", "false");
        subMenuRef.current[idx].className = `hidden ${styles.subMegaMenu}`;
        topMenuArrowRef.current[idx].className = `${styles.topMenuArrow} hidden`;
      })
      if (pageWrapperClasses.includes("blurred") && pageWrapper !== null) {
        pageWrapper.classList.remove("blurred");
      } 
    }
  };

  return (
    <div className={styles.topMenuLinkInitialWrapper}>
      {menuLinks.map((link, idx) => {
        const linkExpand = link.expand ? "expand" : "";
        //const subMenuClass = link.title === "Resources" ? ` ${styles.shaded}` : "";
        const subMenuClass = "";

        const menuLink = 
        <a 
          href={link.url} 
          className={styles.topMenuLink}
          key={`${link.title}-${idx}`}
          onClick={(e) => linkExpand ? topMenuClick(e, idx) : ""}
          ref={(element) => topMenuLinkRef.current[idx] = element}
        >{link.title}
          <span 
            className={`${styles.topMenuArrow} hidden`}
            ref={(element) => topMenuArrowRef.current[idx] = element}
          >
            <Triangle />
          </span>
        </a>
        const subMenu = link.expand !== true && 'child' in link ? 
        <></> : 
        <div className={styles.dropDownWrapper}>
          <div 
            className={`${styles.subMegaMenu} hidden ${linkExpand}${subMenuClass}`}
            ref={(element) => subMenuRef.current[idx] = element}
          >
            {link.child?.map((c, idx) => {
              const sectionBkgroundClass = c.headerLabel === "EDB Trust Center" ? ` ${styles.headerTeal}` : ""
              const header = c.header === true ? 
                  <div 
                    className={`${styles.section}${sectionBkgroundClass}`} 
                    key={`${c.headerLabel}-${idx}`}
                  >
                    <div className={styles.headerLabel}>
                      {c.headerLabel}
                    </div>
                    <div className={styles.menuLinkWrapper}>
                  {c.links.map((links, idx) => {
                    return (
                    <div
                      className={styles.menuLinkSection}
                      key={`${idx}`}
                    >
                      {links.map((l: Links, lidx: number) => {
                        const underline = l.underline ? "border-b-teal" : "";
                        const cta = l.cta ? styles.menuLinkCtaLink : "";
                        const separateCTA = "separateCTA" in l ? l.separateCTA : "";
                        const ctaTitle = "ctaTitle" in l ? l.ctaTitle : "";
                        const eyebrow = "eyebrow" in l ? l.eyebrow : "";
                        const url = "url" in l ? l.url : "";
                        const disabledClass = "disabled" in l ? ` ${styles.disabledLink}` : "";
                        const pictureWidth = typeof l.picture !== "undefined" ? `${styles.pictureSectionMaxWidth}` : "";
                        const doubleArrow = "doubleArrow" in l && l.doubleArrow ? <DoubleArrowSvg /> : "";
                        const secondLevelLinks = "secondaryLinks" in l ? l.secondaryLinks : [];
                        const fontWeightClass = "fontWeight" in l && l.fontWeight === "light" ? ' font-weight-300' : "";
                        const noLink = "noLink" in l && l.noLink ? l.noLink : false;
                        const noHoverClass = "noHover" in l && l.noHover ? styles.noHover : "";

                        return (
                          <>
                            <div key={lidx} className={`${styles.menuLink} ${noHoverClass} ${underline} ${cta} ${pictureWidth}`}>
                              {separateCTA === "" ? 
                                <>
                                  {noLink ? 
                                    <div className="text-white no-link-wrapper">
                                      <div className={`${styles.linkTitle}${fontWeightClass}`}>{l.title} {doubleArrow}</div>
                                      <div className={styles.linkDescription}>{l.description}</div>
                                    </div>
                                    :
                                  <a href={url} className={disabledClass}>
                                    <div className={`${styles.linkTitle}${fontWeightClass}`}>{l.title} {doubleArrow}</div>
                                    <div className={styles.linkDescription}>{l.description}</div>
                                  </a>  
                                }
                                </>
                                :
                                <>
                                {typeof l.picture !== "undefined" && l.picture === "trustCenter" && (
                                    <div className={styles.linkPicture}>
                                      <img 
                                        src={`../images/trust_center.png`} 
                                        alt={`Trust Center picture`}
                                        width={`100px`}
                                        height={`100px`}
                                      />
                                    </div>
                                  )}
                                  <div className={styles.eyebrow}>{eyebrow}</div>
                                  <div className={styles.linkTitle}>{l.title}</div>
                                  <div className={
                                    typeof l.picture !== "undefined" && l.picture === "trustCenter" ? 
                                    styles.linkDescriptionResources : styles.linkDescription
                                  }>{l.description}
                                  </div>
                                  {typeof l.picture !== "undefined" && l.picture === "trustCenter" && (
                                    <div className={styles.arbitraryFloat}></div>
                                  )} 
                                  <a href={url}>
                                    <div className={styles.ctaTitle}>{ctaTitle}</div>
                                  </a>
                                </>                       
                            }
                            </div>
                            {Array.isArray(secondLevelLinks) && secondLevelLinks.map((second: any) => {
                                // Secondary submenu.
                                const secondaryDoubleArrow = "doubleArrow" in second && second.doubleArrow ? <DoubleArrowSvg /> : "";
                                const tertiaryLinks = "tertiaryLinks" in second ? second.tertiaryLinks : [];
                                const fontWeightSecondClass = "fontWeight" in second && second.fontWeight === "light" ? ' font-weight-300' : "";
                                const secondaryUnderline = "secondaryUnderline" in second ? "border-b-teal" : "";
                                const noHoverSecondaryClass = "noHover" in second && second.noHover ? styles.noHover : "";
                                const noLinkSecondary = "noLink" in second && second.noLink ? second.noLink : false;

                                return (
                                  <>
                                    {noLinkSecondary ? 
                                      <div key={second.url} className={`ms-3 p-2 text-white ${styles.menuLink} ${noHoverSecondaryClass} ${secondaryUnderline}`}>
                                        <div className={`${styles.linkTitle}${fontWeightSecondClass}`}>{second.title} {secondaryDoubleArrow}</div>
                                        <div className={styles.linkDescription}>{second.description}</div>
                                      </div>
                                   : 
                                      <div key={second.url} className={`ms-3 p-2 ${styles.menuLink} ${noHoverSecondaryClass} ${secondaryUnderline}`}>
                                        <a href={second.url} className={disabledClass}>
                                          <div className={`${styles.linkTitle}${fontWeightSecondClass}`}>{second.title} {secondaryDoubleArrow}</div>
                                          <div className={styles.linkDescription}>{second.description}</div>
                                        </a>
                                      </div>
                                  }
                                    
                                    {Array.isArray(tertiaryLinks) && tertiaryLinks.map((third: any) => {
                                      // Tertiary submenu...
                                      const tertiaryDoublArrow = "doubleArrow" in third && third.doubleArrow ? <DoubleArrowSvg /> : "";
                                       return (
                                        <div key={third.url} className={`ms-4 p-2 ${styles.menuLink}`}>
                                          <a href={third.url} className={disabledClass}>
                                            <div className={styles.linkTitle}>{third.title} {tertiaryDoublArrow}</div>
                                            <div className={styles.linkDescription}>{third.description}</div>
                                          </a>
                                        </div>
                                       )
                                    })}
                                  </>
                                )
                              })}
                          </>
                        )
                      })}
                    </div>
                    )
                  })}
                  </div>
                </div> 
              : <></>
              return (
                header
              )
            })}
          </div>
        </div>
        return (
          <div className={`${styles.topMenuLinkWrapper} py-2`} key={idx}>
            <div className="main-menu">
              {menuLink}
              {subMenu}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default HeaderDropdowns
